<!--
 * @Author: jiang
 * @Date: 2021-07-12 10:39:48
 * @Description: textbus
-->
<template>
  <div>
    <div
      class="editor"
      ref="editor"
    ></div>
  </div>

</template>

<script>
import { createEditor } from '@textbus/textbus'
import '@textbus/textbus/bundles/textbus.min.css'
import { uploadImage } from '@/api/common/upload'

export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      editor: null,
    }
  },
  watch: {
    value(val) {
      let that=this
      if (that.editor && that.editor.getContents().content !== val) {
       // this.init(val)
         that.editor.setContents(val)
      }
    },
  },
  mounted() {
    this.init(this.value)
  },
  methods: {
    init(content = '') {
       this.$refs.editor.innerHTML=""
      let that =this
      this.editor = createEditor(this.$refs.editor, {
        theme: 'mac-os',
         contents: content,
        uploader(uploadType, prevValue) {
          let promise
          let fileInput
          switch (uploadType) {
            case 'video':
              console.log('上传视频')
              this.$message.warning('暂不支持上传视频')
              break
            case 'image':
              console.log('上传视频')
              fileInput = document.createElement('input')
              fileInput.setAttribute('type', 'file')
              fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp')
              fileInput.style.cssText =
                'position: absolute; left: -9999px; top: -9999px; opacity: 0'
              promise = new Promise(function (resolve) {
                fileInput.addEventListener('change', function (event) {
                  resolve(event.target.files[0])
                  // document.body.removeChild(fileInput)
                })
              })
              document.body.appendChild(fileInput)
              fileInput.click()
              break
            case 'audio':
              console.log('上传音频')
              this.$message.warning('暂不支持上传音频')
              break
          }
          // console.log(uploadType, prevValue)
          return promise
            .then(file => {
              return uploadImage(file)
            })
            .then(res => {
              return res.image_url
            })
        },

      })

      this.$once('hook:beforeDestroy', () => {
        this.editor.destroy()
      })

      this.editor.onChange.subscribe(() => {

        const content = that.editor.getContents()
        const value = content.content

        if (that.value !== value) {
          that.$emit('input', value)
        }

      })
    },
  },
}
</script>
<style lang="scss" scoped>
.hint {
  font-size: 14px;
  color: #f40;
}
</style>
